<template>
  <tr>
    <td class="px-4" style="width: 15%">
      <span style="font-size: 11px">{{ productItem.barCode }}</span>
      <br />
      <span style="font-size: 12px">({{ productItem.productCode }})</span>
      <br />
      <span>{{ productItem.name }}</span>
    </td>
    <td class="px-4 text-right" style="width: 5%">
      <span>{{ productItem.totalQuantityInStock }}</span>
    </td>
    <td class="px-4 text-center" style="width: 20%">
      <div
        v-if="
          productItem.productType === productType.CHASSIS_ENGINE &&
          productItem.chassisNumber
        "
      >
        {{ productItem.chassisNumber }}
      </div>
      <div
        v-if="
          productItem.productType === productType.SERIES &&
          productItem.serialNumber
        "
      >
        {{ productItem.serialNumber }}
      </div>
      <div v-if="editState">
        <b-button
          v-if="
            productItem.productType === productType.CHASSIS_ENGINE ||
            productItem.productType === productType.SERIES
          "
          size="sm"
          variant="warning"
          @click="handleOpenProductStockInfoModal"
        >
          <div
            v-if="
              (productItem.productType === productType.CHASSIS_ENGINE &&
                !productItem.chassisNumber) ||
              (productItem.productType === productType.SERIES &&
                !productItem.serialNumber)
            "
          >
            <i class="fa-solid fa-circle-plus"></i>
            Nhập thông tin
          </div>
          <div v-else>
            <i class="fas fa-edit"></i>
            Chỉnh sửa
          </div>
        </b-button>
      </div>
    </td>
    <td class="px-4 text-right" style="width: 10%;t">
      <div v-if="editState">
        <b-form-input
          v-if="
            productItem.productType === productType.CHASSIS_ENGINE ||
            productItem.productType === productType.SERIES
          "
          :disabled="
            productItem.productType === productType.CHASSIS_ENGINE ||
            productItem.productType === productType.SERIES
          "
          style="height: calc(1.35rem + 1.1rem + 2px); border-color: #007bff"
          v-model="productItem.quantity"
          type="number"
          :min="0"
          :max="1"
          placeholder="Số lượng"
          class="w-100 h-90 text-right"
          @change="checkValidation"
        ></b-form-input>
        <b-form-input
          v-if="productItem.productType === productType.DEFAULT"
          style="height: calc(1.35rem + 1.1rem + 2px); border-color: #007bff"
          v-model="productItem.quantity"
          type="number"
          :min="0"
          placeholder="Số lượng"
          class="w-100 h-90 text-right"
          @change="checkValidation"
        ></b-form-input>
        <b-form-invalid-feedback
          id="input-live-feedback"
          :state="$v.productItem.quantity.minValue"
        >
          Nhập số lượng lớn hơn 0
        </b-form-invalid-feedback>
      </div>
      <span v-else>{{ productItem.quantity }}</span>
    </td>
    <td class="px-4 text-right" style="width: 18%">
      <div v-if="editState">
        <b-form-input
          size="sm"
          style="height: calc(1.35rem + 1.1rem + 2px); border-color: #007bff"
          v-model="productItem.price"
          placeholder="Đơn giá"
          :min="1"
          class="w-100 h-90 text-right"
          @change="checkValidation"
          v-mask="mask"
          :disabled="editPrice"
        ></b-form-input>
        <p style="color: red" v-if="error">Hãy nhập đầy đủ thông tin</p>
      </div>
      <span v-else>{{ convertPrice(productItem.price) }}</span>
    </td>
    <td class="px-4 text-right" style="width: 14%">
      <span>{{ convertPrice(totalPriceOfProduct) }}</span>
    </td>
    <td class="px-4 text-right" style="width: 13%">
      <div v-if="editState">
        <b-form-input
          size="sm"
          style="height: calc(1.35rem + 1.1rem + 2px); border-color: #007bff"
          v-model="productItem.discount"
          placeholder="Chiết khấu"
          :min="1"
          class="w-100 h-90 text-right"
          @change="checkValidation"
          v-mask="mask"
        ></b-form-input>
        <p style="color: red" v-if="error">Hãy nhập đầy đủ thông tin</p>
      </div>
      <span v-else>{{ convertPrice(productItem.discount) }}</span>
    </td>

    <td style="width: 5%" v-if="editState || deleteState">
      <template>
        <div class="d-flex justify-content-center">
          <v-icon
            small
            class="text-danger"
            @click="showDeleteAlert"
            v-b-tooltip
            title="Xóa"
            >mdi-delete
          </v-icon>
        </div>
      </template>
    </td>
  </tr>
</template>

<style>
.input {
  border: 1px solid #ecf0f3;
  border-radius: 5px;
  padding-left: 5px;
}

*:focus {
  outline: none;
}
</style>

<script>
import Swal from 'sweetalert2';
import { convertPrice, currencyMask, unMaskPrice } from '@/utils/common';
import { PRODUCT_TYPE_VEHICLE } from '@/utils/enum';
import { minValue } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

export default {
  mixins: [validationMixin],
  props: {
    productItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    editState: {
      type: Boolean,
      default: false,
    },
    editPrice: {
      type: Boolean,
      default: false,
    },
    deleteState: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      edit: true,
      error: false,
      mask: currencyMask,
      productType: PRODUCT_TYPE_VEHICLE,
    };
  },
  computed: {
    PRODUCT_TYPE_VEHICLE() {
      return PRODUCT_TYPE_VEHICLE;
    },
    totalPriceOfProduct() {
      if (this.productItem.name != '') {
        return (
          this.productItem.quantity * unMaskPrice(this.productItem.price) -
          unMaskPrice(this.productItem.discount)
        );
      } else {
        return this.productItem.totalPrice;
      }
    },
    formatImeiList() {
      if (!this.productItem.IMEI) {
        return '';
      }

      const listImei = this.productItem.IMEI.split(/\r\n|\r|\n/);
      const listImeiNo = listImei.map((imei, index) => {
        const no = index + 1;
        return no + ') ' + imei;
      });

      return listImeiNo.join('\r\n');
    },
  },
  validations: {
    productItem: {
      quantity: {
        minValue: minValue(1),
      },
    },
  },
  created() {},
  updated() {
    this.checkAddRow();
  },
  methods: {
    convertPrice,
    checkValidation() {
      this.error = false;
      this.edit = true;
      let dataResponse = this.productItem;
      dataResponse.price = unMaskPrice(this.productItem.price);
      this.$emit('update', dataResponse);
    },
    showDeleteAlert: function () {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này khỏi phiếu nhập không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.$emit('deleteItem', this.productItem.id);
        }
      });
    },
    checkAddRow() {
      this.edit = true;
    },
    enterClicked() {
      let countLine = 0;
      const tmp = this.productItem.IMEI.split(/\r\n|\r|\n/);
      tmp.forEach((element) => {
        if (element.trim() !== '') {
          countLine++;
        }
      });
      this.productItem.quantity = countLine;
    },
    handleOpenProductStockInfoModal() {
      this.$emit('productStockModal', this.productItem, this.index);
    },
  },
};
</script>
